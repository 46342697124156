<script lang="tsx">
import { defineComponentCoreIcon } from '@core/app/components/core/icon/CoreIcon.vue'

export default defineComponentCoreIcon(
    <svg width="17" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M3.6 3.6V.9c0-.495.405-.9.9-.9h10.8c.495 0 .9.405.9.9v12.6c0 .495-.405.9-.9.9h-2.7v2.7c0 .495-.405.9-.909.9H.909C.405 18 0 17.604 0 17.1V4.5c0-.495.405-.9.909-.9H3.6ZM1.8 5.4v10.8h9V5.4h-9Zm3.6-1.8h7.2v9h1.8V1.8h-9v1.8Z"
            fill="currentColor"
        />
    </svg>
)

</script>

<style lang="scss" scoped>

</style>
